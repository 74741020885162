@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-ext-400-normal.dd6d6486.woff2") format("woff2"), url("roboto-mono-all-400-normal.1c9cb8de.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-400-normal.10dad15d.woff2") format("woff2"), url("roboto-mono-all-400-normal.1c9cb8de.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-greek-400-normal.f2269253.woff2") format("woff2"), url("roboto-mono-all-400-normal.1c9cb8de.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-vietnamese-400-normal.1d3f7e28.woff2") format("woff2"), url("roboto-mono-all-400-normal.1c9cb8de.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-ext-400-normal.5ce7dbab.woff2") format("woff2"), url("roboto-mono-all-400-normal.1c9cb8de.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-400-normal.7b49cb08.woff2") format("woff2"), url("roboto-mono-all-400-normal.1c9cb8de.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("poppins-devanagari-400-normal.5d0185d7.woff2") format("woff2"), url("poppins-all-400-normal.8c0af881.woff") format("woff");
  unicode-range: U+900-97F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("poppins-latin-ext-400-normal.06fb9d99.woff2") format("woff2"), url("poppins-all-400-normal.8c0af881.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("poppins-latin-400-normal.1110a71c.woff2") format("woff2"), url("poppins-all-400-normal.8c0af881.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.d87e81bb.css.map */
